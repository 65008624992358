import React from "react"
import styled from "@emotion/styled"


const Container = styled.section`
  position: relative;
  padding: 5rem 3rem;
  background: url(/img/svg/pattern.svg) center center/15%,
    linear-gradient(to right, #2c5364, #203a43, #0f2027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  @media only screen and (max-width: 56.25em) {
    background: url(/img/svg/pattern.svg) center center/25%,
      linear-gradient(to right, #2c5364, #203a43, #0f2027);
  }
  @media only screen and (max-width: 40em) {
    background: url(/img/svg/pattern.svg) center center/45%,
      linear-gradient(to right, #2c5364, #203a43, #0f2027);
  }
  @media only screen and (max-width: 32.5em) {
    background: url(/img/svg/pattern.svg) center center/65%,
      linear-gradient(to right, #2c5364, #203a43, #0f2027);
    height: auto;
    padding: 3rem;
  }
  overflow: hidden;
  min-height: 40rem;
  svg {
    width: 100%;
    position: absolute;
    bottom: -1px;
    left: 0;
    fill: #fff;
    z-index: 1;
  }

  @media only screen and (min-width: 156em) {
    height: 50rem;
  }
`

const Content = styled.div`
  padding: 10rem 0 5rem;
  margin: 0 auto;
  text-align: center;
  max-width: 75rem;
  @media only screen and (max-width: 52em) {
    padding: 12rem 0;
  }
`

const Primary = styled.h1`
  font-size: 5rem;
  text-transform: uppercase;
  line-height: 1.1;
  color: #fff;
  z-index: 2;
  @media only screen and (max-width: 32.5em) {
    font-size: 3.5rem;
  }
`

const Subtitle = styled.h2`
  color: #fff;
  font-weight: 400;
  font-size: 2.5rem;
  z-index: 2;
  @media only screen and (max-width: 32.5em) {
    font-size: 2.5rem;
  }
`

const Header = ({ title, subtitle, children }) => (
  <Container>
    <Content>
      <Primary>{title}</Primary>
      <Subtitle>{subtitle}</Subtitle>
    </Content>
    {children}
  </Container>
)

export default Header
