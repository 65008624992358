import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import Card from "./Card"
import Logo from "../../static/img/svg/skipTheDepot-small.svg"
import Button from "./Button"

const Wrapper = styled.section`
  position: relative;
  background: #0f2027; /* fallback for old browsers */
  background: linear-gradient(
    to right,
    #2c5364,
    #203a43,
    #0f2027
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  overflow: hidden;
  svg {
    width: 100%;
    position: absolute;
    left: 0;
    fill: #fff;
    z-index: 1;
    :nth-of-type(1) {
      top: -3px;
    }
    :nth-of-type(2) {
      bottom: -3px;
      fill: #efefef;
    }
    @media only screen and (max-width: 32.5em) {
      margin-bottom: 0;
    }
  }
`

const Container = styled.div`
  padding: 10rem 1.5rem;
  position: relative;
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .card {
    width: 30%;
    min-width: 30rem;
    margin: 1.5rem;
    @media only screen and (max-width: 37.5em) {
      width: 100%;
      min-width: 0;
    }
  }
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-align: center;
`

const Secondary = styled.h4`
  font-size: 2.5rem;
  font-weight: 700;
  color: #2196f3;
  padding-top: 1.5rem;
`

const Body = styled.p`
  padding: 2rem 0;
  color: #505050;
`

const svgStyles = {
  position: "absolute",
  left: "50%",
  top: "0",
  height: "7rem",
  width: "7rem",
  transform: "translate(-50%, -50%)",
  padding: "1rem",
  borderRadius: "50%",
  background: "#2196f3",
  fill: "#fff",
}

const InternalCards = ({
  recycling,
  fundraising,
  locations,
  services,
  pricing,
}) => (
  <Wrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600">
      <path d="M1115.22-803.868c207.043-11.551 346.933 48.473 348.29 145.624 1.028 97.472-136.808 232.391-273.88 370.534C1052.644-149.415 916.506-7.746 774.332 31.987 632.157 71.719 483.947 9.515 262.517 18.019c-221.514 8.352-516.333 87.262-605.393 55.763-89.56-31.48 26.64-173.368 178.1-308.464 151.46-135.095 338.095-263.55 568.386-369.104 230.208-105.706 504.569-188.53 711.61-200.082z" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600">
      <path d="M869.344 647.383c163.633 68.4 245.45 178.048 205.32 269.422-40.518 91.548-203.372 164.995-367.005 241.749-163.634 76.928-328.046 157.337-453.888 140.106-125.842-17.23-213.113-132.1-386.486-208.854-173.374-76.928-432.85-115.914-487.783-179.44-55.324-63.701 93.894-151.942 267.267-220.342 173.374-68.4 370.902-117.132 592.197-127.749 221.295-10.79 466.745 16.708 630.378 85.108z" />
    </svg>

    <Container>
      <Content>
        {recycling && (
          <Card>
            <Logo style={svgStyles} />
            <Details>
              <div>
                <Secondary>How does SkipTheDepot work?</Secondary>
                <Body>
                  SkipTheDepot aims to make recycling as simple and convient as
                  possible
                </Body>
              </div>
              <Link to="/recycling" style={{ textDecoration: "none" }}>
                <Button>Find out more</Button>
              </Link>
            </Details>
          </Card>
        )}
        {services && (
          <Card>
            <Logo style={svgStyles} />
            <Details>
              <div>
                <Secondary>What does SkipTheDepot do?</Secondary>
                <Body>
                  We don't just help with household recycling, we also help with
                  commercial businesses, charities, and more!
                </Body>
              </div>
              <Link to="/services" style={{ textDecoration: "none" }}>
                <Button>Find out more</Button>
              </Link>
            </Details>
          </Card>
        )}
        {pricing && (
          <Card>
            <Logo style={svgStyles} />
            <Details>
              <div>
                <Secondary>How much does SkipTheDepot cost?</Secondary>
                <Body>
                  SkipTheDepot is free! SkipTheDepot pays you for recycling
                  bottles and cans.
                </Body>
              </div>
              <Link to="/pricing" style={{ textDecoration: "none" }}>
                <Button>Find out more</Button>
              </Link>
            </Details>
          </Card>
        )}
        {locations && (
          <Card>
            <Logo style={svgStyles} />
            <Details>
              <div>
                <Secondary>Is SkipTheDepot in your area?</Secondary>
                <Body>
                  SkipTheDepot is always expanding into new communities and
                  cities.
                </Body>
              </div>
              <Link to="/locations" style={{ textDecoration: "none" }}>
                <Button>Find out more</Button>
              </Link>
            </Details>
          </Card>
        )}
        {fundraising && (
          <Card>
            <Logo style={svgStyles} />
            <Details>
              <div>
                <Secondary>What's a bottle drive?</Secondary>
                <Body>
                  Fundraising and donating to local charities is easy with
                  SkipTheDepot by donating straight through the app.
                </Body>
              </div>
              <Link
                to="/fundraising"
                style={{ textDecoration: "none" }}
              >
                <Button>Find out more</Button>
              </Link>
            </Details>
          </Card>
        )}
      </Content>
    </Container>
  </Wrapper>
)

export default InternalCards
