import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Header from "../components/Header"
import Breadcrumbs from "../components/Breadcrumbs"
import FundraisingContent from "../components/FundraisingContent"
import InternalCards from "../components/InternalCards"
import Cta from "../components/Cta"
import Ready from "../components/Ready"

export const FundraisingPageTemplate = ({
  title,
  subtitle,
  fundraising,
  breadcrumbs,
}) => (
  <>
    <Header title={title} subtitle={subtitle}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 126">
        <path d="M356.911 617.042c-100.653 31.097-200.718 9.396-294.94-15.765-94.081-25.412-182.462-54.032-242.975-105.337-60.622-51.42-93.63-125.391-65.44-210.324C-218.618 200.703-129.342 104.694.171 91.013c129.766-13.816 299.911 54.445 428.778 24.542C557.816 85.652 645.153-42.281 707.804-81.22c62.762-38.822 100.84 11.35 190.136 40.53 89.296 29.179 229.812 37.364 225.577 83.56-4.346 46.082-153.553 130.059-254.81 198.793-101.367 68.619-154.673 122.11-232.09 191.054-77.526 68.83-178.91 152.976-279.706 184.324z" />
      </svg>
    </Header>
    <Breadcrumbs data={breadcrumbs} />
    <FundraisingContent data={fundraising} />
    <InternalCards locations services fundraising />
    <Ready>
      <Cta href="https://app.skipthedepot.com/register">Get started</Cta>
      <Cta to="/faq" variant>
        Visit our FAQ
      </Cta>
    </Ready>
  </>
)

const FundraisingPage = ({
  data: {
    markdownRemark: {
      frontmatter: { title, subtitle, seoDesc, fundraising },
    },
  },
  location,
}) => {
  const breadcrumbs = location.pathname.split("/")
  return (
    <Layout
      title="Fundraising | SkipTheDepot | Start Fundraising Today"
      seoDesc={seoDesc}
    >
      <FundraisingPageTemplate
        title={title}
        subtitle={subtitle}
        fundraising={fundraising}
        breadcrumbs={breadcrumbs}
      />
    </Layout>
  )
}

export default FundraisingPage

export const PageQuery = graphql`
  query fundraisingQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "fundraising-page" } }) {
      frontmatter {
        title
        subtitle
        seoDesc
        fundraising {
          title
          subtitle
          data {
            name
            body
            image {
              relativePath
            }
            learnUrl
            data
          }
        }
      }
    }
  }
`
